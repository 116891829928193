import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 52.112 52.111" {...props}>
<path d="m48.283 6.665c-.338-.336-.735-.505-1.196-.505-.229 0-.712.188-1.444.559-.735.372-1.517.786-2.337 1.248-.823.459-1.797.875-2.922 1.247-1.123.371-2.162.559-3.119.559-.885 0-1.663-.168-2.336-.505-2.229-1.044-4.169-1.823-5.814-2.337-1.646-.513-3.416-.771-5.311-.771-3.272 0-6.999 1.064-11.177 3.188-.862.43-1.48.763-1.88 1.007l-.397-2.911c.897-.779 1.476-1.914 1.476-3.195 0-2.347-1.902-4.249-4.249-4.249s-4.25 1.902-4.25 4.249c0 1.531.818 2.862 2.032 3.61l5.74 42.09c.171 1.253 1.243 2.162 2.474 2.162.112 0 .226-.009.341-.021 1.368-.188 2.326-1.447 2.139-2.815l-2.307-16.916c4.186-2.077 7.807-3.124 10.853-3.124 1.293 0 2.554.191 3.783.583 1.23.391 2.254.813 3.067 1.272.812.461 1.773.887 2.88 1.274 1.107.39 2.2.585 3.279.585 2.726 0 5.989-1.027 9.796-3.08.478-.248.827-.492 1.049-.73.222-.239.332-.58.332-1.021v-20.258c0-.459-.167-.858-.502-1.195z"/>

    </Svg>
  );
};

export default Icon;
