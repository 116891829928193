import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'VIVE-FTM LP',
    lpAddresses: {
      250: '0xf561C4dA5c86F5B35E761E637aA6387219780bfA',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },


  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BOUJE-FTM LP',
    lpAddresses: {
      250: '0x91af3c43b77217c1f9622BFC2Cd313e213332901',
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 11,
    risk: 5,
    lpSymbol: 'BOUJE-USDC LP',
    lpAddresses: {
      250: '0xacf6728c977E349440827375C73Ee23bCE4Db291',
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'FTM-USDC LP',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },


  {
    pid: 0,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'VIVE',
    lpAddresses: {
      250: '0x2D2Ce3AD0Bf68624c8C80F26e0863214af284218'
    },
    tokenSymbol: 'VIVE',
    tokenAddresses: {
      250: '0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  
  {
    pid: 14,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BASTILLE',
    lpAddresses: {
      250: '0x06538cAbA45Ce6697EA89c03aaf3F3F735830a6a'
    },
    tokenSymbol: 'BASTILLE',
    tokenAddresses: {
      250: '0xcef2b88d5599d578c8d92E7a6e6235FBfaD01eF4'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BOUJE',
    lpAddresses: {
      250: '0xacf6728c977E349440827375C73Ee23bCE4Db291'
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },


  {
    pid: 5,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      250: '0xFdef392aDc84607135C24ca45DE5452d77aa10DE'
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 6,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WFTM',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c'
    },
    tokenSymbol: 'WFTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      250: '0xa572BdF049382F1F98f9a430788dadD51A303969'
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 8,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      250: '0x6B5340dFcd7D509Ea931cC4E69462797DbBc0197'
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 9,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      250: '0x484237bc35cA671302d19694c66d617142FBC235'
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      250: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 10,
    risk: 5,
    lpSymbol: 'VIVE-USDC LP',
    lpAddresses: {
      250: '0x2D2Ce3AD0Bf68624c8C80F26e0863214af284218',
    },
    tokenSymbol: 'VIVE',
    tokenAddresses: {
      250: '0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 12,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DEVIL',
    lpAddresses: {
      250: '0x473d7d3a56be2e05a35161fe5fbd2624ac339698'
    },
    tokenSymbol: 'DEVIL',
    tokenAddresses: {
      250: '0x174c7106aeecdc11389f7dd21342f05f46ccb40f'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 13,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'SPECT',
    lpAddresses: {
      250: '0xb326bbaC9Ca368FF20D0Fe1a51fF25f5F9C1E310'
    },
    tokenSymbol: 'SPECT',
    tokenAddresses: {
      250: '0x2834d4F4cC40bd7D78c07E2D848358364222C272'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

]

export default farms

