import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 -42 512.001 512" {...props}>
<path d="m506.09375 51.367188-36.058594-48.988282c-2.332031-3.171875-7.085937-3.171875-9.421875 0l-36.054687 48.988282c-3.835938 5.214843-5.90625 11.511718-5.90625 17.972656v21.671875h-115.949219v-21.671875c0-6.460938-2.066406-12.757813-5.90625-17.972656l-36.054687-48.988282c-2.332032-3.171875-7.089844-3.171875-9.421876 0l-36.054687 48.988282c-3.839844 5.214843-5.910156 11.511718-5.910156 17.972656v21.671875h-116.007813v-21.671875c0-6.460938-2.070312-12.757813-5.90625-17.972656l-36.054687-48.988282c-2.335938-3.171875-7.089844-3.171875-9.421875 0l-36.058594 48.988282c-3.835938 5.214843-5.90625 11.511718-5.90625 17.976562v340.324219c0 9.570312 7.800781 17.332031 17.421875 17.332031h58.503906c9.621094 0 17.421875-7.761719 17.421875-17.332031v-31.394531h116.007813v31.394531c0 9.570312 7.800781 17.332031 17.421875 17.332031h58.507812c9.621094 0 17.417969-7.761719 17.417969-17.332031v-31.394531h115.949219v31.394531c0 9.570312 7.800781 17.332031 17.421875 17.332031h58.503906c9.621094 0 17.421875-7.761719 17.421875-17.332031v-340.324219c0-6.464844-2.070312-12.761719-5.90625-17.976562zm-296.738281 89.035156v69.441406h-116.007813v-69.441406zm-116.007813 188.480468v-69.648437h116.007813v69.648437zm325.304688-188.480468v69.441406h-115.949219v-69.441406zm-115.949219 188.480468v-69.648437h115.949219v69.648437zm0 0"/>



    </Svg>
  );
};

export default Icon;
