import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 511.971 511.971" {...props}>
<path d="m511.971 255.901c0-4.014-1.611-7.881-4.468-10.693-3.001-1.879-42.404-44.562-113.628-46.421 49.087-51.709 46.124-109.587 46.813-112.588-.029-8.247-6.753-14.971-15-15-2.999.688-60.829-2.318-112.592 46.818-1.853-71.167-44.539-110.632-46.417-113.633-5.625-5.713-15.762-5.713-21.387 0-1.879 2.999-44.573 42.396-46.425 113.623-51.707-49.09-109.583-46.12-112.584-46.808-8.247.029-14.971 6.753-15 15 .688 2.999-2.318 60.829 46.818 112.592-71.167 1.849-110.632 44.538-113.633 46.417-2.857 2.812-4.468 6.679-4.468 10.693s1.611 7.881 4.468 10.693c3.001 1.862 42.071 44.372 113.941 46.13-49.482 51.867-46.434 109.876-47.126 112.879.029 8.247 6.753 14.971 15 15 3.001-.69 60.85 2.298 112.592-46.815 1.853 71.166 44.539 110.629 46.417 113.63 2.813 2.856 6.68 4.453 10.693 4.453s7.881-1.597 10.693-4.453c1.879-3.001 44.564-42.455 46.419-113.626 51.711 49.083 109.589 46.121 112.59 46.811 8.247-.029 14.971-6.753 15-15-.692-3.001 2.347-61.02-47.131-112.881 71.863-1.763 110.946-44.266 113.947-46.128 2.856-2.812 4.468-6.679 4.468-10.693zm-255.986 45c-24.814 0-45-20.186-45-45s20.186-45 45-45 45 20.186 45 45-20.185 45-45 45z"/>


    </Svg>
  );
};

export default Icon;
