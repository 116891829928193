import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 511.999 511.999" {...props}>
<path d="M187.639,377.31c-2.419,1.004-5.069,1.564-7.851,1.564c-11.325,0-20.506-9.181-20.506-20.505    c0-2.843,0.58-5.55,1.626-8.011c-13.456-15.831-13.789-39.06-0.997-55.257c-0.396-1.59-0.629-3.245-0.629-4.957    c0-1.561,0.19-3.075,0.52-4.536h-23.579c-24.065,0-43.643-19.578-43.643-43.644v-8.59H23.542C10.561,233.374,0,243.935,0,256.916    v202.905c0,12.981,10.561,23.542,23.542,23.542h213.294c12.98,0,23.541-10.561,23.541-23.542v-9.774L187.639,377.31z     M80.589,447.099c-11.325,0-20.505-9.181-20.505-20.505c0-11.325,9.181-20.505,20.505-20.505c11.324,0,20.505,9.18,20.505,20.505    C101.094,437.918,91.914,447.099,80.589,447.099z M80.589,378.874c-11.325,0-20.505-9.181-20.505-20.505    c0-11.324,9.181-20.505,20.505-20.505c11.324,0,20.505,9.181,20.505,20.505C101.094,369.693,91.914,378.874,80.589,378.874z     M80.589,310.649c-11.325,0-20.505-9.181-20.505-20.505c0-11.324,9.181-20.505,20.505-20.505c11.324,0,20.505,9.181,20.505,20.505    C101.094,301.468,91.914,310.649,80.589,310.649z M179.788,447.099c-11.325,0-20.506-9.181-20.506-20.505    c0-11.325,9.18-20.505,20.506-20.505c11.325,0,20.505,9.18,20.505,20.505C200.293,437.918,191.113,447.099,179.788,447.099z"/>
<path d="M349.518,15.516H136.223c-12.98,0-23.541,10.561-23.541,23.542v202.906h0.001c0,12.981,10.561,23.542,23.541,23.542    h52.843l117.721-117.721c17.016-17.015,44.705-17.017,61.721,0l4.551,4.551V39.058C373.06,26.077,362.499,15.516,349.518,15.516z     M197.002,216.716c-8.377,8.378-21.959,8.378-30.337,0c-8.377-8.377-8.377-21.959,0-30.337c8.378-8.377,21.96-8.377,30.337,0    C205.38,194.756,205.38,208.339,197.002,216.716z M197.002,94.643c-8.377,8.377-21.959,8.377-30.337,0    c-8.377-8.378-8.377-21.96,0-30.337c8.378-8.377,21.96-8.377,30.337,0C205.38,72.683,205.38,86.266,197.002,94.643z     M258.04,155.68c-8.378,8.377-21.96,8.377-30.337,0c-8.378-8.378-8.378-21.96,0-30.337c8.377-8.377,21.959-8.377,30.337,0    C266.417,133.72,266.417,147.303,258.04,155.68z M319.076,94.643c-8.377,8.377-21.96,8.377-30.337,0    c-8.377-8.377-8.377-21.96,0-30.337s21.96-8.377,30.337,0C327.454,72.683,327.454,86.266,319.076,94.643z"/>
<path d="M505.115,312.821l-150.82-150.822c-4.59-4.59-10.617-6.884-16.646-6.884s-12.057,2.294-16.646,6.884L177.526,305.475    c-9.18,9.179-9.18,24.114,0,33.292l150.822,150.821c0,0,0,0,0,0.001c4.446,4.446,10.357,6.895,16.645,6.895    c6.289,0,12.2-2.449,16.646-6.895l143.476-143.477C514.294,336.933,514.294,321.999,505.115,312.821z M286.957,335.193    c-8.752,8.752-22.94,8.752-31.692,0s-8.752-22.94,0-31.692c8.752-8.752,22.94-8.752,31.692,0S295.709,326.441,286.957,335.193z     M319.028,239.738c8.752-8.751,22.941-8.751,31.692,0c8.751,8.752,8.751,22.94,0,31.692c-8.752,8.752-22.94,8.752-31.692,0    S310.276,248.49,319.028,239.738z M363.615,411.851c-8.752,8.752-22.94,8.752-31.692,0c-8.752-8.752-8.752-22.94,0-31.692    c8.752-8.751,22.94-8.751,31.692,0C372.366,388.91,372.366,403.099,363.615,411.851z M427.378,348.088    c-8.752,8.752-22.94,8.752-31.692,0c-8.752-8.751-8.752-22.94,0-31.692c8.752-8.752,22.94-8.752,31.692,0    C436.129,325.147,436.129,339.336,427.378,348.088z"/>

</Svg>

  );
};

export default Icon;
