import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 1469.000000 1086.000000" {...props}>
<g transform="translate(0.000000,1086.000000) scale(0.100000,-0.100000)"
fill="#ffffffbf" stroke="none">
<path d="M5310 10843 c-919 -115 -1762 -352 -2445 -688 -324 -159 -662 -371
-835 -523 -239 -210 -607 -912 -924 -1759 -75 -202 -216 -600 -234 -663 -5
-19 -35 -115 -66 -212 -352 -1112 -623 -2425 -731 -3538 -43 -451 -75 -1163
-56 -1266 13 -70 44 -131 136 -269 421 -634 1130 -1140 2115 -1508 489 -183
1088 -331 1591 -392 258 -31 358 -19 442 56 38 33 619 816 633 853 3 8 -52 28
-168 60 -800 225 -1579 587 -2107 978 -140 104 -184 149 -216 220 -24 54 -27
69 -23 142 5 94 27 145 88 206 61 61 112 83 206 88 72 4 89 1 137 -21 30 -13
79 -45 109 -69 206 -168 600 -400 948 -559 742 -338 1585 -559 2470 -648 361
-37 550 -45 970 -45 420 0 609 8 970 45 1314 132 2567 571 3395 1189 135 101
144 105 255 105 87 0 100 -3 149 -30 100 -55 152 -142 159 -261 8 -151 -42
-221 -281 -392 -370 -264 -867 -524 -1363 -715 -195 -75 -573 -199 -752 -247
-85 -23 -121 -37 -118 -46 9 -24 568 -789 605 -827 83 -89 178 -110 397 -88
574 58 1266 235 1879 481 195 78 630 297 803 404 475 294 837 631 1097 1021
94 140 122 197 136 270 10 56 -2 558 -21 832 -94 1373 -395 2859 -865 4268
-278 836 -572 1516 -852 1975 -140 229 -232 338 -373 445 -602 456 -1480 816
-2475 1014 -331 66 -708 121 -830 121 -116 0 -221 -59 -275 -152 -29 -52 -110
-266 -110 -293 0 -9 24 -17 73 -25 367 -60 935 -204 1317 -335 454 -156 946
-380 1299 -590 199 -118 256 -193 255 -335 0 -157 -107 -284 -262 -311 -90
-16 -156 5 -315 99 -965 574 -2254 921 -3649 982 -477 22 -1092 -24 -1685
-125 -940 -160 -1766 -447 -2458 -855 -150 -88 -188 -103 -265 -103 -122 0
-232 70 -287 181 -23 47 -28 70 -27 132 0 144 53 213 253 332 468 279 1062
532 1656 704 295 86 711 183 963 224 47 8 72 16 72 25 0 25 -72 221 -103 278
-31 59 -83 109 -147 143 -35 18 -178 28 -260 17z m223 -4811 c287 -78 550
-299 700 -589 53 -103 107 -258 134 -383 25 -119 25 -408 0 -535 -125 -618
-599 -1032 -1146 -1002 -496 28 -907 423 -1028 987 -26 122 -26 450 1 569 28
131 62 226 121 346 151 305 407 524 706 604 110 29 112 30 279 26 119 -2 173
-7 233 -23z m4161 -6 c356 -107 637 -394 765 -781 52 -159 71 -277 71 -449 0
-239 -40 -418 -139 -621 -228 -467 -676 -720 -1132 -640 -461 80 -820 464
-926 990 -25 125 -25 414 0 537 100 496 439 877 872 979 65 15 110 18 240 15
139 -3 172 -7 249 -30z"/>
</g>
</Svg>


  );
};

export default Icon;
