import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512.001 512.001" {...props}>
<path d="M497,431h-26V301H340.78c-12.443,0-24.444-5.222-32.926-14.327L256,231.015l-51.855,55.66    C195.664,295.778,183.663,301,171.22,301H41v130H15c-8.284,0-15,6.716-15,15v51c0,8.284,6.716,15,15,15h482    c8.284,0,15-6.716,15-15v-51C512,437.716,505.284,431,497,431z M176,411c0,8.284-6.716,15-15,15h-60c-8.284,0-15-6.716-15-15v-60    c0-8.284,6.716-15,15-15h60c8.284,0,15,6.716,15,15V411z M296,411c0,8.284-6.716,15-15,15h-60c-8.284,0-15-6.716-15-15v-60    c0-8.284,6.716-15,15-15h60c8.284,0,15,6.716,15,15V411z M441,431H331v-40c0-30.327,24.673-55,55-55c30.327,0,55,24.673,55,55V431    z"/>
<path d="M511.398,251.79l-52.63-180C456.897,65.395,451.033,61,444.37,61H407V29.987c8.167-0.136,14.748-6.788,14.748-14.987    c0-8.284-6.716-15-15-15c-9.208,0-78.395,0-90,0c-8.284,0-15,6.716-15,15s6.716,15,15,15H317v31H67.63    c-6.663,0-12.527,4.395-14.397,10.791l-52.63,180c-1.325,4.533-0.438,9.426,2.395,13.206C5.831,268.777,10.278,271,15,271h156.22    c4.162,0,8.138-1.729,10.975-4.775L256,187.005l73.806,79.22c2.837,3.045,6.812,4.775,10.975,4.775H497    c4.723,0,9.17-2.225,12.003-6.004C511.836,261.217,512.723,256.324,511.398,251.79z M377,61h-30V30h30V61z"/>

    </Svg>
  );
};

export default Icon;
