import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
font-family: 'Raleway', serif;}
  body {
    background-color: #191919;
    background-image: url("/images/egg/bg.gif");
    background-attachment:fixed;
    background-size: cover;
    background-repeat: no-repeat;


    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle