import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
<path d="m497 201h-35.14c-10.301-27.017-28.273-51.092-51.422-68.636-2.759-2.091-5.574-4.075-8.438-5.964v-70.4c0-8.284-6.716-15-15-15-36.219 0-66.522 25.808-73.491 60h-132.561c-40.483 0-79.183 15.029-108.97 42.318-11.817 10.826-21.707 23.135-29.497 36.505l-4.404-5.138c-5.893-6.875-8.909-15.87-7.871-24.865.995-8.621 5.304-16.248 12.164-21.543 6.584-5.081 8.372-14.498 3.431-21.187-4.953-6.706-14.41-8.082-21.067-3.09-13.628 10.221-22.51 25.795-24.369 42.729-1.858 16.933 3.434 34.063 14.52 46.996l14.127 16.482c-3.896 12.314-6.222 25.166-6.842 38.32-2.018 42.854 13.132 83.501 42.66 114.451 6.254 6.555 12.973 12.479 20.079 17.757l17.449 78.518c1.524 6.864 7.612 11.747 14.642 11.747h60c8.284 0 15-6.716 15-15v-45h110v45c0 8.284 6.716 15 15 15h60c7.03 0 13.118-4.883 14.643-11.746l17.475-78.638c.44-.326.883-.648 1.32-.98 23.149-17.543 41.121-41.619 51.422-68.636h35.14c8.284 0 15-6.716 15-15v-80c0-8.284-6.716-15-15-15zm-135.006-5c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-92-5h-70c-8.284 0-15-6.716-15-15s6.716-15 15-15h70c8.284 0 15 6.716 15 15s-6.716 15-15 15z"/>

</Svg>

  );
};

export default Icon;
