import { darkColors, lightColors } from "../../theme/colors";
import { NavTheme } from "./types";

export const light: NavTheme = {
  background: lightColors.contrast,
};

export const dark: NavTheme = {
  background: darkColors.contrast,
};
