import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
<path d="M495.304,478.609h-61.217V228.174c0-9.223-7.479-16.696-16.696-16.696h-66.783c-9.217,0-16.696,7.473-16.696,16.696    v250.435h-33.391V161.391c0-9.223-7.479-16.696-16.696-16.696h-66.783c-9.217,0-16.696,7.473-16.696,16.696v317.217h-33.391    V294.957c0-9.223-7.479-16.696-16.696-16.696H83.478c-9.217,0-16.696,7.473-16.696,16.696v183.652H33.391V16.696    C33.391,7.473,25.913,0,16.696,0S0,7.473,0,16.696v478.609C0,504.527,7.479,512,16.696,512h478.609    c9.217,0,16.696-7.473,16.696-16.696C512,486.082,504.521,478.609,495.304,478.609z"/>

    </Svg>
  );
};

export default Icon;
