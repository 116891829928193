import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 512.000000 512.000000" {...props}>
<path d="m493.578.403-155.72 36.53c-12.056 2.828-15.682 18.289-6.118 26.176l31.215 25.742-106.952 134.099-106.951-134.099 31.215-25.742c9.554-7.879 5.951-23.345-6.118-26.176l-155.72-36.53c-9.527-2.237-18.609 5.122-18.423 14.889l3.09 162.41c.238 12.537 14.887 19.249 24.541 11.287l32.015-26.403 121.352 151.683v182.738c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-182.738l121.352-151.683 32.015 26.403c9.675 7.978 24.303 1.225 24.541-11.287l3.09-162.41c.185-9.77-8.897-17.123-18.424-14.889z"/>

    </Svg>
  );
};

export default Icon;
