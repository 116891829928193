import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade (Spooky)',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://spookyswap.finance/swap?outputCurrency=0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A',
      },
      {
        label: 'Liquidity',
        href: 'https://spookyswap.finance/add/FTM/0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Community Pools',
  //   icon: 'GroupsIcon',
  //   href: '/community',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Layers',
    icon: 'LayerIcon',
    items: [
      {
        label: 'BASITLLE L3 (NEW)',
        href: 'https://bastille.bouje.finance/',
      },
      {
        label: 'L1',
        href: 'https://www.bouje.finance/',
      },
    ],
  },
  {
    label: 'Dividends',
    icon: 'ByofIcon',
    items: [
      {
        label: 'In-house',
        href: '/dividends',
      },
      {
        label: 'Spectre Defi',
        href: 'https://spectrefi.com/pools',
      },
      {
        label: 'Xmas Past',
        href: 'https://www.xmas-past.com/pools',
      },
    ],
  },


  {
    label: 'Games',
    icon: 'DogIcon',
    href: 'https://game.bouje.finance/r/8bw4AsJ2',
  },
  {
    label: 'Résistance Day',
    icon: 'FlagIcon',
    href: '/ResistanceDay',
  }, 
  {
    label: 'Vaults',
    icon: 'VaultIcon',
    items: [
      {
        label: 'Devil Finance (Incentivised)',
        href: 'https://devilfinance.io',
      },
      {
        label: 'YieldWolf',
        href: 'https://yieldwolf.finance/fantom/vivelabouje',
      },
    ],
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'CMC',
        href: 'https://coinmarketcap.com/currencies/vive-la-bouje/',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/vive-la-bouje',
      },
      {
        label: 'CoinBase',
        href: 'https://www.coinbase.com/price/vive-la-bouje',
      },
      {
        label: 'Dex Screener',
        href: 'https://dexscreener.com/fantom/0xf561c4da5c86f5b35e761e637aa6387219780bfa',
      },
      {
        label: 'Kek.tools',
        href: 'https://kek.tools/t/0xe509db88b3c26d45f1fff45b48e7c36a8399b45a?pair=0x2d2ce3ad0bf68624c8c80f26e0863214af284218',
      },
      {
        label: 'Zoo Coin',
        href: 'https://charts.zoocoin.cash/charts?exchange=SpookySwap&pair=0xf561C4dA5c86F5B35E761E637aA6387219780bfA-inverted',
      },
      {
        label: 'Spooky Info',
        href: 'https://info.spookyswap.finance/token/0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A',
      },
      {
        label: 'Dex Tools',
        href: 'https://www.dextools.io/app/fantom/pair-explorer/0xf561c4da5c86f5b35e761e637aa6387219780bfa',
      },
      {
        label: 'Nomics',
        href: 'https://nomics.com/assets/VIVE-Vive-La-Bouje?utm_source=twitter&utm_medium=bot&utm_campaign=new_asset&utm_content=vive&d=1643325520',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Github',
      //   href: 'https://github.com/polydaedalus',
      // },
      {
        label: 'Docs',
        href: 'https://docs.bouje.finance/',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/65bDq2uFjy',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/fantom/defi/vive-la-bouje',
      },
      {
        label: '0xTracker',
        href: 'https://0xtracker.app/farms',
      },
      {
        label: 'ApeOClock',
        href: 'https://www.apeoclock.com/launch/vive-la-bouje-farm-launch/',
      },
      {
        label: 'FarmScan',
        href: 'https://ftm.farmscan.io/address/0x1277dd1dcbe60d597aaca80738e1de6cb95dcb54#mainpup',
      },
      {
        label: 'DefiLlama',
        href: 'https://defillama.com/protocol/vivelabouje',
      },
      {
        label: 'VFat',
        href: 'https://vfat.tools/fantom/vivelabouje/',
      },
      {
        label: 'Tin.Network',
        href: 'https://tin.network/en/farms',
      },
      {
        label: 'CookieTrack',
        href: 'https://cookietrack.io/',
      },
     // {
     //   label: 'Vfat.tools',
     //   href: 'https://vfat.tools/polygon/platinumfinance/',
     // }
    ],
  },
]

export default config
