import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 511.999 511.999" {...props}>
<path d="M423.596,168.775l77.415-21.503c7.982-2.217,12.655-10.485,10.438-18.468c-2.217-7.981-10.484-12.656-18.468-10.438    l-96.232,26.73c-0.005,0.002-0.01,0.003-0.016,0.004l-125.734,34.925V15.004c0-8.284-6.716-15-15-15s-15,6.716-15,15v173.355    l-133.444,37.067c-0.005,0.002-0.01,0.003-0.014,0.004l-96.552,26.819c-7.981,2.217-12.655,10.485-10.439,18.468    c1.844,6.639,7.875,10.988,14.445,10.988c1.328,0,2.68-0.178,4.022-0.551l60.186-16.718L2.141,392.885    c-1.398,2.33-2.137,4.998-2.137,7.717c0,61.423,49.973,111.394,111.397,111.394c61.426,0,111.398-49.972,111.398-111.394    c0-2.719-0.739-5.387-2.138-7.718L134.4,249.104l233.997-64.996l-77.062,128.446c-1.398,2.332-2.138,4.999-2.138,7.717    c0,61.425,49.973,111.396,111.397,111.396c29.755,0,57.729-11.588,78.771-32.629c21.041-21.041,32.628-49.014,32.626-78.769    c0.002-2.718-0.736-5.385-2.135-7.716L423.596,168.775z M181.31,385.601H41.496l69.906-116.521L181.31,385.601z M330.689,305.27    l69.907-116.519l69.908,116.519H330.689z"/>



    </Svg>
  );
};

export default Icon;
