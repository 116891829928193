import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#ffffffbf" stroke="none">
<path d="M2435 4868 c-36 -73 -67 -137 -70 -143 -3 -6 -7 -5 -11 2 -16 34
-166 253 -173 253 -14 0 -97 -264 -121 -380 -11 -58 -23 -115 -26 -128 -4 -19
-15 -9 -83 75 -94 117 -289 309 -301 297 -10 -11 -30 -183 -30 -258 0 -31 -3
-56 -6 -56 -3 0 -26 18 -52 39 -26 21 -83 65 -127 96 l-80 57 -3 -133 c-2
-117 15 -321 35 -399 l6 -24 -29 19 c-89 59 -244 141 -348 185 -65 28 -120 49
-122 47 -13 -12 93 -373 144 -489 l21 -47 -47 20 c-115 50 -471 134 -489 116
-3 -4 25 -71 65 -148 l71 -141 -37 6 c-20 3 -95 9 -165 13 l-129 6 48 -74 c66
-104 143 -207 222 -297 l68 -77 -130 -3 c-114 -3 -403 -36 -415 -47 -6 -7 56
-73 146 -155 l82 -75 -72 -18 c-119 -30 -237 -67 -237 -75 0 -20 300 -223 403
-273 26 -13 47 -25 47 -28 0 -4 -56 -28 -124 -54 -111 -43 -366 -174 -366
-188 0 -17 305 -137 439 -173 l84 -22 -54 -34 c-123 -75 -380 -284 -379 -307
1 -9 123 -37 232 -54 l78 -11 -41 -47 c-170 -194 -171 -194 -152 -205 11 -7
112 -11 274 -11 l255 0 -54 -73 c-104 -142 -246 -392 -221 -392 74 0 372 62
483 100 l54 18 -38 -82 c-60 -131 -159 -430 -146 -443 5 -5 177 59 248 92 29
13 53 23 55 21 1 -1 -1 -16 -6 -32 -14 -49 -45 -273 -39 -280 15 -14 274 136
395 229 l70 54 -4 -206 c-3 -113 -1 -239 4 -280 l8 -74 63 52 c34 29 91 79
126 111 l63 60 7 -58 c10 -89 48 -262 57 -262 17 0 206 232 270 330 35 54 66
99 69 99 3 1 15 -36 27 -82 36 -140 151 -427 172 -427 20 0 191 344 222 448
11 38 29 58 29 31 0 -31 155 -278 250 -398 l44 -55 22 66 c12 36 32 108 45
160 l23 94 67 -76 c79 -89 157 -165 162 -159 20 23 61 462 49 525 -5 27 -4 28
14 14 10 -8 42 -35 69 -60 85 -78 337 -260 360 -260 8 0 5 140 -5 229 -5 47
-8 86 -7 88 2 1 59 -26 128 -61 68 -35 134 -67 145 -70 l22 -7 -18 98 c-23
127 -53 241 -90 346 -17 47 -30 88 -30 91 0 3 21 -5 48 -16 125 -56 461 -152
477 -136 13 12 -115 279 -196 410 -26 43 -43 78 -36 78 7 0 53 -7 102 -16 98
-17 435 -32 435 -19 0 10 -81 124 -142 200 l-48 60 32 2 c101 8 263 25 274 30
8 3 14 9 14 13 0 15 -231 240 -309 300 -44 35 -81 66 -81 70 0 4 24 11 53 14
124 17 470 121 464 140 -7 21 -207 149 -337 215 l-135 68 60 23 c112 43 342
167 405 218 l25 21 -50 22 c-27 12 -96 39 -152 59 l-102 36 77 56 c87 63 172
136 172 147 0 18 -311 82 -460 96 l-75 7 115 116 c120 121 257 288 246 299 -3
4 -78 7 -165 7 l-158 1 47 70 c56 83 120 193 120 204 0 18 -296 -21 -441 -58
l-105 -26 37 70 c66 123 180 427 165 442 -14 14 -353 -117 -460 -177 l-52 -30
28 110 c35 140 76 440 60 440 -10 0 -175 -97 -235 -138 l-45 -32 4 93 c3 50 2
125 -3 166 l-8 73 -95 -73 c-52 -40 -145 -123 -206 -185 l-112 -112 -7 77
c-14 153 -78 461 -96 461 -23 0 -226 -274 -294 -396 l-28 -50 -42 122 c-44
128 -162 384 -177 384 -5 0 -38 -60 -73 -132z m131 -33 c30 -63 74 -174 98
-245 l44 -130 -38 -87 c-20 -49 -47 -119 -59 -158 -13 -38 -25 -77 -28 -85 -3
-8 -15 30 -28 85 -28 122 -73 249 -131 373 -37 79 -42 97 -33 120 17 44 112
242 116 242 3 0 29 -52 59 -115z m-276 -61 c60 -100 61 -100 46 -135 -44 -105
-106 -402 -106 -509 l0 -45 -49 101 c-27 55 -65 127 -86 160 -28 44 -38 68
-36 94 4 68 43 235 84 362 l41 128 22 -27 c13 -16 50 -73 84 -129z m779 1 c29
-131 41 -204 50 -312 l8 -96 -49 -66 c-198 -263 -302 -557 -313 -880 l-4 -124
-33 -35 c-58 -60 -63 -59 -91 11 l-25 62 35 69 c134 266 173 618 107 964 l-17
88 43 75 c98 169 248 368 261 347 5 -7 17 -53 28 -103z m-1278 -92 c122 -126
243 -278 233 -294 -3 -5 -6 -84 -6 -175 l0 -165 -76 112 c-45 67 -122 159
-190 231 l-115 119 7 82 c12 157 19 207 26 207 4 0 58 -53 121 -117z m1759
-112 l0 -149 -57 -43 c-78 -58 -247 -228 -312 -314 -51 -67 -53 -68 -47 -35 3
19 9 102 12 183 l7 148 116 118 c97 98 260 241 277 241 2 0 4 -67 4 -149z
m-2077 29 c151 -115 135 -86 147 -259 9 -146 33 -293 64 -394 l14 -48 -93 89
c-51 49 -111 102 -134 118 -47 33 -54 51 -76 200 -17 116 -31 364 -21 364 5 0
49 -31 99 -70z m1006 -222 c21 -63 49 -161 60 -218 21 -101 22 -105 5 -234
-12 -91 -15 -169 -11 -256 4 -110 2 -133 -18 -205 -13 -44 -32 -100 -43 -125
l-20 -45 -57 118 c-56 115 -58 121 -66 231 -9 123 -17 165 -55 304 -26 90 -26
96 -15 214 13 135 38 259 78 391 l27 89 38 -75 c20 -41 55 -126 77 -189z
m1336 68 c-18 -137 -37 -234 -67 -333 -20 -71 -22 -73 -87 -115 -36 -24 -102
-72 -145 -107 -45 -37 -76 -56 -71 -45 48 116 105 349 120 494 l6 66 92 60
c98 65 160 102 165 99 1 -1 -5 -54 -13 -119z m-1982 -186 c78 -96 173 -243
191 -295 8 -22 22 -76 31 -120 23 -104 49 -182 91 -277 31 -69 35 -88 41 -202
5 -101 3 -126 -7 -126 -7 0 -54 42 -104 93 -75 76 -95 103 -116 156 -28 75
-114 223 -171 297 -44 57 -78 148 -108 287 -21 96 -41 266 -41 345 l0 46 66
-64 c36 -36 93 -99 127 -140z m918 -55 c14 -123 7 -342 -16 -450 -20 -98 -76
-267 -108 -332 l-25 -47 -17 79 c-16 71 -17 88 -4 175 7 52 14 178 14 280 2
179 3 188 33 277 17 50 44 121 59 157 l28 65 12 -52 c7 -29 17 -97 24 -152z
m-1575 56 c51 -27 122 -68 158 -92 61 -40 67 -47 81 -99 48 -173 148 -385 248
-526 73 -103 198 -231 295 -303 l71 -53 7 -64 c3 -36 5 -66 3 -68 -2 -2 -32 5
-67 15 -61 19 -63 21 -93 82 -141 280 -378 511 -686 668 -54 27 -101 56 -106
64 -39 70 -157 432 -157 481 0 15 96 -26 246 -105z m2360 57 c-18 -151 -73
-362 -132 -496 -13 -29 -50 -83 -82 -120 -71 -80 -136 -173 -186 -264 -38 -68
-110 -143 -200 -207 l-48 -34 7 69 c14 147 25 188 84 304 56 111 99 219 121
307 12 47 92 163 184 267 51 59 243 236 255 236 2 0 0 -28 -3 -62z m-1381
-143 c61 -125 70 -157 85 -312 6 -62 22 -156 37 -210 30 -110 38 -187 25 -253
l-8 -45 -46 78 c-32 54 -50 99 -58 145 -16 87 -64 237 -106 334 -32 71 -35 87
-41 215 -3 76 -3 161 1 188 l8 49 24 -39 c14 -22 49 -89 79 -150z m970 48
c-13 -195 -32 -276 -90 -387 -55 -105 -93 -201 -120 -298 -14 -50 -105 -198
-122 -198 -11 0 5 233 22 315 40 195 143 430 256 590 30 41 56 75 57 75 2 0 1
-44 -3 -97z m1115 -3 c0 -49 -182 -458 -212 -476 -7 -5 -60 -28 -118 -51 -289
-118 -531 -313 -692 -558 -33 -49 -65 -93 -71 -96 -18 -10 -119 -30 -124 -25
-2 2 1 31 8 65 l12 61 101 67 c118 78 280 231 361 341 79 107 158 248 202 361
20 53 41 102 47 109 26 35 398 198 479 211 4 0 7 -4 7 -9z m-2633 -274 c98
-94 106 -105 163 -223 33 -68 88 -165 122 -214 80 -114 83 -119 107 -207 l20
-72 -57 44 c-220 169 -395 424 -491 714 -17 51 -31 95 -31 98 0 13 73 -48 167
-140z m-932 24 c121 -25 185 -43 306 -88 92 -33 98 -37 117 -78 20 -40 112
-191 148 -241 15 -20 7 -19 -67 16 -109 50 -248 95 -378 123 l-103 23 -46 80
c-56 99 -95 185 -83 185 5 0 52 -9 106 -20z m3015 -30 c-37 -93 -113 -235
-174 -325 -101 -149 -311 -350 -427 -410 l-29 -16 24 65 c34 89 42 104 96 163
64 70 132 167 180 255 21 40 63 96 92 125 53 52 236 193 250 193 4 0 -1 -22
-12 -50z m-2475 -163 c261 -141 453 -321 596 -561 32 -55 57 -101 55 -103 -2
-3 -37 12 -77 33 -55 27 -101 63 -179 138 -58 55 -145 128 -194 161 -65 44
-102 78 -141 130 -76 102 -163 245 -148 245 4 0 44 -20 88 -43z m3344 6 c-12
-21 -49 -79 -81 -129 l-59 -92 -67 -7 c-147 -14 -366 -68 -496 -121 -11 -4 8
26 45 72 35 43 83 109 107 145 l43 66 92 26 c125 35 264 60 425 76 9 1 7 -11
-9 -36z m-3969 -73 c62 -7 88 -14 98 -27 7 -10 47 -65 89 -123 42 -58 122
-151 178 -207 56 -57 101 -103 100 -103 -1 0 -44 9 -96 20 -52 10 -129 22
-172 26 l-78 7 -69 76 c-76 84 -202 249 -237 310 l-22 39 62 -5 c34 -2 100 -8
147 -13z m3361 -92 c-88 -128 -182 -221 -269 -268 -94 -51 -181 -112 -257
-181 -40 -37 -81 -62 -138 -85 -45 -18 -83 -31 -85 -29 -10 10 113 171 201
262 113 117 239 211 382 285 104 54 208 99 214 93 1 -2 -20 -37 -48 -77z
m-3084 42 c254 -66 408 -143 534 -269 49 -48 128 -115 176 -148 48 -34 101
-74 117 -89 28 -28 149 -204 144 -210 -4 -3 -102 14 -181 32 -47 11 -92 32
-143 65 -89 58 -225 128 -310 159 -74 26 -191 123 -303 251 -97 109 -188 239
-167 239 8 0 68 -14 133 -30z m3861 -129 c-10 -28 -185 -225 -267 -302 l-96
-89 -105 0 c-58 0 -134 -5 -170 -10 -35 -5 -66 -8 -67 -6 -2 1 33 30 76 65 87
68 221 204 291 294 l45 57 148 0 c85 0 147 -4 145 -9z m-370 -43 c-57 -73
-180 -198 -253 -258 -112 -91 -176 -129 -255 -151 -90 -24 -221 -79 -310 -130
-66 -37 -102 -45 -274 -64 l-58 -7 32 47 c58 84 151 175 210 205 78 40 183
112 260 180 36 31 79 64 96 73 61 32 263 96 365 116 132 26 147 28 187 30 l34
1 -34 -42z m-1729 -270 c11 -27 24 -48 29 -48 6 0 32 20 59 45 l50 44 12 -69
c13 -73 22 -81 57 -55 29 22 34 18 34 -20 0 -43 14 -45 89 -10 l54 25 -7 -37
c-22 -132 -30 -121 71 -100 l62 13 -16 -35 c-8 -20 -21 -53 -28 -73 l-14 -38
40 0 40 0 -16 -30 c-8 -16 -15 -33 -15 -39 0 -5 32 -15 70 -21 l71 -12 -46
-49 c-25 -26 -45 -53 -45 -59 0 -6 12 -16 28 -22 15 -7 29 -13 31 -15 2 -1 -8
-12 -22 -24 -15 -11 -27 -24 -27 -29 0 -5 27 -24 60 -42 33 -18 60 -35 59 -38
0 -3 -27 -18 -60 -33 -32 -16 -59 -31 -59 -35 0 -11 12 -25 61 -68 l45 -41
-61 -12 c-87 -17 -92 -22 -66 -59 18 -26 19 -31 5 -36 -9 -3 -24 -6 -35 -6
-27 0 -24 -25 11 -87 l30 -53 -75 7 c-43 3 -75 2 -75 -3 0 -5 8 -39 18 -75
l18 -66 -45 17 c-25 10 -58 20 -73 24 -27 6 -28 5 -28 -33 l0 -40 -34 14 c-42
18 -46 11 -46 -76 0 -32 -3 -59 -6 -59 -3 0 -27 19 -54 41 -55 47 -77 46 -87
-1 l-6 -31 -29 28 c-28 27 -30 27 -43 9 -8 -10 -22 -38 -32 -62 -10 -24 -20
-44 -23 -44 -3 0 -20 25 -38 55 -18 30 -38 55 -45 55 -7 0 -30 -25 -52 -55
-37 -51 -54 -57 -55 -18 0 6 -9 32 -20 58 l-20 46 -29 -20 c-16 -12 -31 -21
-34 -21 -3 0 -8 16 -11 35 l-7 35 -32 -16 c-18 -9 -47 -27 -64 -41 l-33 -24 0
77 c0 83 0 83 -66 57 -15 -6 -16 -2 -11 32 l6 38 -47 -7 c-26 -3 -59 -9 -74
-12 l-28 -6 25 66 c14 37 25 69 25 71 0 3 -34 5 -75 5 l-75 0 36 56 c37 58 39
84 5 84 -28 0 -36 18 -17 39 9 10 16 23 16 29 0 11 -99 52 -128 52 -10 0 -1
11 23 26 48 32 85 65 85 75 0 4 -25 23 -55 41 -30 18 -55 35 -55 39 0 3 27 17
60 32 33 14 60 30 60 35 0 4 -11 19 -26 33 l-25 24 32 10 c18 6 34 16 36 22 2
6 -17 36 -42 67 l-45 56 62 0 c80 0 96 9 80 45 -16 35 -17 35 23 35 42 0 44
11 16 92 l-19 55 66 -19 c37 -10 71 -18 75 -18 5 0 6 33 2 75 l-7 76 64 -36
c36 -19 68 -32 71 -28 4 3 7 22 7 40 l0 35 36 -22 c19 -12 37 -21 39 -18 2 2
12 34 23 72 l18 69 44 -52 c24 -28 49 -51 55 -51 7 0 18 14 26 31 l12 32 27
-33 27 -33 25 34 c14 19 34 47 44 63 16 26 19 27 25 10 5 -11 17 -40 28 -66z
m-1847 -16 c51 -43 117 -93 145 -111 29 -19 52 -35 50 -37 -1 -1 -47 2 -102 8
-100 9 -335 -2 -435 -22 -56 -11 -64 -6 -183 109 l-89 86 31 8 c63 16 259 35
373 36 l118 1 92 -78z m109 53 c160 -28 225 -50 307 -103 90 -60 196 -114 293
-152 45 -17 95 -48 140 -85 38 -32 68 -59 66 -61 -2 -2 -43 1 -92 7 -70 9
-106 20 -165 49 -83 42 -216 90 -300 110 -92 21 -208 91 -338 204 -52 45 -53
46 -25 46 15 0 67 -7 114 -15z m3398 -168 c-137 -103 -272 -171 -369 -187
-100 -15 -237 -53 -334 -90 -69 -27 -90 -30 -160 -28 l-81 3 73 52 c44 31 97
58 130 68 96 26 192 65 300 121 86 44 120 56 200 68 116 19 148 22 226 24 l59
2 -44 -33z m286 12 c93 -12 306 -57 318 -68 4 -4 -30 -37 -75 -72 -131 -103
-146 -112 -176 -105 -189 41 -295 55 -516 69 l-39 2 90 53 c49 29 114 73 144
97 60 49 54 48 254 24z m-3638 -70 c79 -9 112 -18 187 -54 109 -51 205 -86
306 -110 79 -19 114 -37 223 -118 39 -28 64 -54 59 -59 -4 -4 -59 -16 -122
-25 -111 -16 -115 -16 -204 5 -122 29 -214 42 -314 42 -98 0 -158 18 -322 96
-109 52 -335 193 -325 202 2 3 46 10 97 17 119 16 292 18 415 4z m-488 -77
c125 -90 232 -151 354 -202 97 -41 97 -44 -11 -55 -35 -4 -104 -17 -155 -30
l-92 -23 -100 51 c-94 48 -264 156 -329 210 l-29 24 119 32 c65 18 124 34 129
36 29 10 53 1 114 -43z m4026 -91 c178 -37 171 -30 77 -85 -111 -65 -259 -129
-383 -166 -97 -29 -108 -30 -223 -24 -81 5 -155 2 -226 -7 -58 -8 -134 -13
-170 -12 -64 1 -216 35 -233 52 -9 9 81 69 168 114 28 13 85 30 127 37 101 15
235 52 328 90 l75 30 180 -4 c130 -3 208 -10 280 -25z m308 -83 c51 -17 109
-39 131 -49 l38 -17 -58 -38 c-79 -50 -263 -144 -347 -176 l-68 -27 -104 34
c-57 19 -134 40 -172 48 -37 8 -61 17 -53 20 8 3 51 18 95 32 92 31 299 129
365 173 25 17 53 31 63 31 11 1 60 -13 110 -31z m-3721 -113 c59 -15 165 -30
248 -36 138 -11 231 -31 289 -63 23 -13 19 -15 -65 -34 -289 -68 -606 -45
-883 66 l-64 25 44 13 c44 14 209 41 284 47 22 2 41 4 42 5 1 1 48 -9 105 -23z
m-428 -90 c99 -39 255 -80 361 -95 174 -24 433 -7 600 39 l70 20 57 -35 c32
-20 58 -37 58 -38 0 -2 -26 -21 -57 -43 l-58 -39 -55 13 c-30 8 -131 17 -225
20 -145 4 -188 2 -294 -16 -145 -25 -297 -73 -436 -140 l-101 -48 -84 23
c-110 29 -241 76 -337 120 l-77 35 32 20 c102 61 407 197 447 199 6 0 50 -16
99 -35z m3541 -44 c50 -11 128 -32 175 -47 99 -31 99 -31 -70 -70 -237 -54
-471 -53 -689 2 -111 28 -198 59 -185 66 5 3 34 14 64 24 45 14 94 18 260 20
113 2 223 7 245 12 62 15 102 13 200 -7z m-713 -105 c160 -54 238 -67 418 -73
191 -6 323 9 496 58 l108 30 131 -65 c132 -66 315 -180 315 -196 0 -20 -334
-113 -449 -126 -57 -6 -59 -5 -152 50 -196 117 -429 194 -654 216 -107 11
-244 8 -316 -6 -21 -4 -37 5 -79 44 l-53 48 54 26 c30 15 59 27 66 27 6 1 58
-14 115 -33z m-1892 -50 l65 -13 -85 -40 c-62 -30 -104 -43 -155 -48 -99 -9
-205 -32 -311 -66 -77 -25 -107 -29 -199 -29 -60 0 -149 5 -199 12 l-91 12 90
42 c160 74 337 124 510 145 88 10 290 2 375 -15z m2177 -92 c188 -27 359 -88
538 -188 87 -49 89 -51 60 -57 -46 -10 -191 -10 -280 0 -52 6 -104 20 -148 40
-83 37 -238 86 -327 102 -44 8 -90 26 -144 58 -75 44 -77 46 -50 52 56 13 248
9 351 -7z m-2020 -43 c11 -6 -1 -22 -49 -65 -89 -82 -132 -109 -223 -140 -104
-36 -204 -83 -285 -133 -75 -46 -103 -56 -220 -79 -102 -19 -446 -32 -462 -16
-23 23 248 217 412 296 77 37 112 47 185 56 110 13 273 51 340 79 45 19 63 21
169 16 66 -3 126 -9 133 -14z m-1032 -76 c61 -8 137 -15 170 -15 l60 0 -98
-53 c-114 -61 -249 -154 -335 -232 l-61 -56 -98 16 c-54 8 -120 20 -146 26
-42 10 -46 13 -35 27 54 65 380 302 416 302 10 0 67 -7 127 -15z m2879 -25
c94 -43 228 -86 327 -105 62 -11 100 -26 160 -62 113 -67 231 -158 329 -254
101 -97 101 -97 -79 -80 -236 23 -429 76 -515 141 -78 59 -205 133 -283 165
-78 33 -147 87 -224 176 l-54 62 135 -6 c124 -5 141 -8 204 -37z m-1676 -58
c21 -14 -25 -121 -57 -135 -80 -33 -223 -102 -266 -129 -199 -126 -405 -339
-523 -544 l-35 -62 -96 -30 c-53 -17 -114 -35 -136 -41 -63 -16 -278 -52 -283
-47 -12 13 154 282 242 392 l41 51 125 27 c307 68 554 199 763 408 98 97 111
107 150 111 23 2 47 4 52 5 6 1 16 -2 23 -6z m-178 -21 c0 -19 -187 -185 -273
-242 -158 -106 -349 -187 -537 -227 l-93 -21 64 66 c100 102 217 191 273 209
92 29 219 87 314 141 107 62 252 104 252 74z m1442 -37 c31 -6 46 -18 93 -80
118 -154 322 -322 494 -408 93 -47 237 -102 316 -122 77 -20 88 -31 181 -196
55 -96 146 -289 140 -295 -7 -7 -241 58 -333 93 -165 62 -167 63 -217 163
-146 290 -385 541 -643 674 -100 51 -89 37 -128 165 -5 13 35 16 97 6z m1322
-58 c88 -66 218 -184 280 -253 55 -61 61 -58 -115 -73 l-126 -11 -95 93 c-93
92 -240 210 -328 261 l-45 26 170 4 c94 1 174 4 179 5 5 1 41 -22 80 -52z
m-1152 18 c60 -19 105 -42 163 -85 78 -56 228 -139 311 -172 57 -22 310 -275
293 -292 -3 -2 -52 12 -109 31 -216 73 -410 187 -563 334 -82 78 -197 210
-183 210 3 0 42 -12 88 -26z m-1498 -86 c-56 -95 -93 -142 -141 -180 -73 -58
-182 -166 -240 -240 -59 -74 -202 -168 -356 -231 l-38 -16 25 42 c147 246 398
479 646 596 89 42 116 50 104 29z m161 -65 c-17 -96 -65 -214 -109 -267 -66
-82 -122 -166 -172 -263 -60 -113 -80 -138 -180 -225 -122 -104 -355 -260
-369 -247 -7 8 73 228 117 319 78 162 134 241 220 309 78 62 189 176 236 242
28 38 97 87 197 138 33 17 62 31 64 31 2 0 0 -17 -4 -37z m1316 -84 c180 -120
365 -331 484 -552 l48 -89 -43 22 c-138 70 -280 181 -328 256 -52 79 -184 236
-231 273 -24 20 -54 60 -80 110 -22 43 -41 82 -41 85 0 11 94 -41 191 -105z
m-218 -7 c62 -45 89 -73 135 -141 31 -47 105 -135 164 -197 116 -121 153 -182
222 -367 38 -102 91 -305 82 -314 -8 -9 -157 102 -244 181 -67 62 -123 120
-194 202 -10 11 -39 70 -64 130 -44 107 -95 201 -160 296 -30 45 -61 149 -77
260 l-6 38 32 -16 c18 -9 67 -41 110 -72z m-2083 51 c-19 -15 -82 -73 -140
-129 l-105 -102 -64 -6 c-81 -9 -379 -9 -426 0 l-35 6 39 47 c22 25 67 75 101
110 l61 63 112 -7 c149 -9 338 8 487 44 3 0 -11 -11 -30 -26z m1510 -88 l27
-46 -29 -110 c-51 -200 -54 -460 -8 -673 20 -92 79 -277 102 -319 7 -14 1 -44
-24 -115 -36 -105 -166 -373 -184 -379 -20 -7 -111 222 -159 401 l-24 88 44
87 c55 111 109 265 137 395 19 85 22 132 22 296 0 141 -5 214 -16 262 -17 74
-19 67 40 146 28 38 31 36 72 -33z m-256 -110 c7 -94 5 -106 -23 -190 -38
-115 -56 -196 -71 -315 -9 -77 -20 -113 -56 -190 -52 -109 -168 -286 -249
-378 l-55 -63 0 163 c0 260 32 429 101 543 53 87 109 208 139 300 25 74 60
127 143 213 61 64 61 64 71 -83z m-198 35 c-11 -54 -28 -98 -50 -133 -52 -82
-106 -194 -141 -292 -17 -50 -42 -107 -56 -127 -38 -55 -177 -213 -210 -237
l-30 -22 7 28 c52 230 88 325 156 410 77 96 110 147 159 244 29 58 63 105 107
151 36 37 68 66 70 63 2 -2 -3 -40 -12 -85z m697 8 c78 -107 93 -135 112 -218
23 -95 64 -209 109 -297 41 -81 54 -147 62 -303 6 -110 -9 -339 -25 -382 -6
-18 -13 -13 -52 43 -67 95 -115 177 -163 281 -67 145 -84 207 -90 344 -3 77
-15 165 -31 240 -21 92 -26 132 -21 179 11 96 29 185 38 185 4 0 32 -33 61
-72z m164 -17 c35 -41 66 -91 77 -123 29 -80 103 -224 157 -304 34 -51 54 -95
69 -151 23 -88 50 -236 50 -272 0 -20 -2 -22 -16 -10 -27 23 -139 170 -181
239 -22 36 -43 83 -47 105 -14 75 -68 230 -111 320 -38 79 -65 186 -65 256 0
17 9 9 67 -60z m1094 39 c115 -30 269 -50 381 -50 l98 0 64 -81 c35 -45 78
-101 96 -125 l31 -44 -33 0 c-136 0 -459 39 -498 60 -9 5 -35 33 -58 63 -22
30 -74 89 -116 131 -41 43 -68 75 -59 72 8 -3 50 -15 94 -26z m-1621 -218 c22
-239 -31 -521 -144 -757 l-43 -90 -12 85 c-7 47 -13 135 -14 195 -1 99 2 120
30 205 33 102 58 215 69 319 6 62 36 144 71 198 l18 26 7 -34 c4 -19 12 -85
18 -147z m198 98 c24 -63 30 -95 35 -196 4 -67 16 -168 28 -225 28 -132 33
-220 20 -311 -18 -116 -42 -218 -51 -213 -15 9 -80 218 -100 321 -27 133 -35
316 -21 434 11 91 50 275 56 268 2 -2 17 -37 33 -78z m-1268 -239 c-53 -86
-127 -252 -166 -375 l-38 -119 -80 -37 c-45 -21 -107 -47 -140 -59 l-58 -22 6
28 c25 104 140 409 174 459 4 6 68 40 142 76 74 36 144 73 155 81 34 28 36 19
5 -32z m2335 -123 c39 -25 98 -60 132 -78 l62 -32 31 -87 c58 -160 120 -404
106 -419 -8 -7 -53 14 -171 79 l-90 49 -23 108 c-29 133 -71 261 -123 371 -21
46 -39 86 -39 89 0 3 10 -4 23 -15 12 -12 54 -41 92 -65z m-2034 -66 c-12 -23
-67 -232 -76 -289 -6 -37 -14 -48 -83 -101 -90 -69 -187 -132 -295 -191 -68
-38 -77 -40 -77 -24 0 24 15 114 35 208 l15 71 63 34 c135 73 242 150 357 252
36 32 66 58 68 58 1 0 -2 -8 -7 -18z m1874 -291 c59 -47 161 -121 202 -145 3
-2 22 -166 27 -239 l5 -67 -87 59 c-102 68 -183 132 -274 216 l-65 60 -12 105
c-7 58 -19 132 -27 165 -7 33 -14 64 -14 69 0 5 38 -29 84 -76 46 -47 118
-114 161 -147z m-1702 108 c-11 -58 -16 -147 -17 -291 -2 -190 -4 -209 -21
-229 -30 -33 -186 -169 -195 -169 -15 0 -22 264 -10 394 l11 130 82 80 c46 45
100 102 121 129 21 26 40 46 42 44 2 -2 -4 -41 -13 -88z m1320 -39 c25 -36 73
-96 106 -133 l61 -68 0 -97 c0 -103 -12 -238 -32 -361 l-11 -75 -78 82 c-43
46 -90 97 -103 115 l-25 32 15 95 c14 97 20 392 8 458 -8 44 -4 41 59 -48z
m-943 -248 c11 -73 11 -82 -8 -118 -59 -113 -290 -405 -303 -382 -7 14 -49
242 -49 269 0 8 27 47 61 85 74 85 196 265 241 357 l33 69 6 -101 c4 -55 12
-136 19 -179z m550 226 c0 -7 29 -71 64 -143 56 -115 122 -225 195 -322 22
-30 23 -34 11 -85 -17 -73 -60 -222 -66 -227 -11 -11 -184 249 -240 360 l-44
89 14 53 c21 72 46 212 46 253 0 19 5 34 10 34 6 0 10 -6 10 -12z"/>
</g>

         </Svg>
  );
};

export default Icon;
