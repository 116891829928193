import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 2,
    tokenName: 'WFTM',
    stakingTokenName: QuoteToken.VIVE,
    stakingTokenAddress: '0xe509db88b3c26d45f1fff45b48e7c36a8399b45a',
    contractAddress: {
      250: '0xf70f36a064e7A85972bAa7d4fa82A15803982B4F'
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://vive.bouje.finance/',
    harvest: true,
    tokenPerBlock: '0.00017',
    sortOrder: 1,
    isFinished: false, 
    tokenDecimals: 18,
    burnFee: 0
  },

  {
    sousId: 4,
    tokenName: 'DAI',
    stakingTokenName: QuoteToken.VIVE,
    stakingTokenAddress: '0xe509db88b3c26d45f1fff45b48e7c36a8399b45a',
    contractAddress: {
      250: '0x593e5B8b8F4d1A98dEC88A6afBD531aDd8a56C9b'
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://vive.bouje.finance/',
    harvest: true,
    tokenPerBlock: '0.0012',
    sortOrder: 1,
    isFinished: true, 
    tokenDecimals: 18,
    burnFee: 0
  }
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
